(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/results/assets/javascripts/results-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/results/assets/javascripts/results-actions.js');
"use strict";

const _excluded = ["hasActiveFilter"],
  _excluded2 = ["hasActiveFilter"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

const {
  services
} = svs.components.marketplaceData.results;
const {
  dialogWrapper
} = svs.components.marketplaceData;
const BROWSE_GAMES_RESULTS_FETCH_INIT = 'BROWSE_GAMES_RESULTS/BROWSE_GAMES_RESULTS_FETCH_INIT';
const BROWSE_GAMES_RESULTS_FETCH_SUCCESS = 'BROWSE_GAMES_RESULTS/BROWSE_GAMES_RESULTS_FETCH_SUCCESS';
const BROWSE_GAMES_RESULTS_FETCH_FAILURE = 'BROWSE_GAMES_RESULTS/BROWSE_GAMES_RESULTS_FETCH_FAILURE';

const fetchResultsAction = (groupId, count) => (dispatch, getState) => {
  dispatch({
    type: BROWSE_GAMES_RESULTS_FETCH_INIT
  });
  const _getState$BrowseGames = getState().BrowseGamesFilter,
    {
      hasActiveFilter
    } = _getState$BrowseGames,
    filter = _objectWithoutProperties(_getState$BrowseGames, _excluded);
  services.fetchResults(_objectSpread(_objectSpread({
    hasActiveFilter
  }, filter), {}, {
    products: Object.keys(filter.products).filter(p => filter.products[p])
  }), 0, (error, results) => {
    if (error) {
      dispatch({
        type: BROWSE_GAMES_RESULTS_FETCH_FAILURE,
        error: error.message
      });
    } else {
      const {
        games,
        totalCount,
        offset,
        count
      } = results;
      dispatch({
        type: BROWSE_GAMES_RESULTS_FETCH_SUCCESS,
        games,
        totalCount,
        offset,
        count
      });
    }
  }, groupId, count);
};
const BROWSE_GAMES_RESULTS_FETCH_NEXT_INIT = 'BROWSE_GAMES_RESULTS/BROWSE_GAMES_RESULTS_FETCH_NEXT_INIT';
const BROWSE_GAMES_RESULTS_FETCH_NEXT_SUCCESS = 'BROWSE_GAMES_RESULTS/BROWSE_GAMES_RESULTS_FETCH_NEXT_SUCCESS';
const BROWSE_GAMES_RESULTS_FETCH_NEXT_FAILURE = 'BROWSE_GAMES_RESULTS/BROWSE_GAMES_RESULTS_FETCH_NEXT_FAILURE';
const fetchResultsNextAction = (retailView, groupId) => (dispatch, getState) => {
  const {
    offset,
    totalCount
  } = getState().BrowseGamesResults;
  const _getState$BrowseGames2 = getState().BrowseGamesFilter,
    {
      hasActiveFilter
    } = _getState$BrowseGames2,
    filter = _objectWithoutProperties(_getState$BrowseGames2, _excluded2);
  if (offset >= totalCount) {
    return;
  }
  dispatch({
    type: BROWSE_GAMES_RESULTS_FETCH_NEXT_INIT
  });
  services.fetchResults(_objectSpread(_objectSpread({
    hasActiveFilter
  }, filter), {}, {
    products: Object.keys(filter.products).filter(p => filter.products[p])
  }), offset, (error, results) => {
    if (error) {
      dispatch({
        type: BROWSE_GAMES_RESULTS_FETCH_NEXT_FAILURE,
        error: error.message
      });
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Kunde inte hämta fler',
        type: 'error'
      }));
    } else {
      const {
        games,
        totalCount,
        offset,
        count
      } = results;
      dispatch({
        type: BROWSE_GAMES_RESULTS_FETCH_NEXT_SUCCESS,
        games,
        totalCount,
        offset,
        count
      });
    }
  }, retailView, groupId);
};
setGlobal('svs.components.marketplaceData.results.actions', {
  BROWSE_GAMES_RESULTS_FETCH_INIT,
  BROWSE_GAMES_RESULTS_FETCH_FAILURE,
  BROWSE_GAMES_RESULTS_FETCH_SUCCESS,
  fetchResultsAction,
  BROWSE_GAMES_RESULTS_FETCH_NEXT_INIT,
  BROWSE_GAMES_RESULTS_FETCH_NEXT_SUCCESS,
  BROWSE_GAMES_RESULTS_FETCH_NEXT_FAILURE,
  fetchResultsNextAction
});

 })(window);